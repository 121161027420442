import {FC} from 'react';
import {Marker, ProdCardTab} from './TabButton.styles';

interface TabButtonProps {
  title?: string;
  onClick?: () => void;
  testID?: string;
  active: boolean;
  className?: string;
  tabFullWidth?: boolean;
  activeRef?: any;
  disabled?: boolean;
  hasMarker?: boolean;
}

export interface StyledTabBtnProps {
  active?: boolean | undefined;
  tabFullWidth?: boolean | undefined;
  disabled?: boolean;
}

const TabButton: FC<TabButtonProps> = ({
  title,
  onClick,
  active,
  testID,
  className,
  tabFullWidth = false,
  activeRef,
  disabled = false,
  hasMarker
}) => {
  return (
    <ProdCardTab
      active={active}
      onClick={onClick}
      data-testid={testID}
      className={className}
      tabFullWidth={tabFullWidth}
      ref={active ? activeRef : null}
      disabled={disabled}>
      {title ?? ''}
      {hasMarker && <Marker/>}
    </ProdCardTab>
  );
};

export default TabButton;
