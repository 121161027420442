import {CatalogProductCalcResult} from '@api/types/CatalogProduct';
import {CalcResult} from '@api/types/MainPage';
import {createContext} from 'react';

export const RegionSelectedContext = createContext([false, () => null] as [
  boolean,
  (regionSelected: boolean) => void,
]);

export const CalcResultContext = createContext([null, () => null] as [
  CatalogProductCalcResult | CalcResult | null,
  (calcResult: CatalogProductCalcResult) => void,
]);
