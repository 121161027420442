import React, {FC, ReactNode, RefObject, useContext, useEffect, useState} from 'react';

import {getCalcListCategory} from '@api/main';
import {
  CalcSettings,
  CatalogProductCalcResult,
  Competitor,
} from '@api/types/CatalogProduct';
import MainPageCalcResult from '@components/molecules/MainPageCalc/MainPageCalcResult';
import {ICalcSettingsState} from '@components/molecules/ProductCard/ProductCardCalc';
import TabBtnGroup from '@components/molecules/TabBtnGroup';
import {RootState} from '@store';
import {GlobalContainer} from '@styles/pages/_app.styles';
import {CalcResultContext, RegionSelectedContext} from '@utils/contexts';
import {FormikProps} from 'formik';
import {useSelector} from 'react-redux';
import {
  MainPageCalcContainer,
  MainPageCalcTitleText,
  MainPageCalcTitleWrapper,
  MainPageCalcWrapper,
  TabsWrapper,
  Title,
} from './MainPageCalc.styles';

interface MainPageCalcProps {
  title?: string;
  description?: string | ReactNode;
  calcSettings: CalcSettings;
  relevantCompetitors: Competitor[];
  testID?: string;
  testSubmit?: (values: any) => any;
  FormCalc: React.FC<{
    setCalcResult?: (values: CatalogProductCalcResult) => void;
    setCalcResultError?: (error: string) => void;
    setCalcResultLoading?: (values: boolean) => void;
    calcSettings: CalcSettings;
    relevantCompetitors: Competitor[];
    categories: Partial<Array<{title: string; value: string}>> | any[];
    activeTab: string;
    formikRef?: RefObject<FormikProps<ICalcSettingsState>>;
    background?: string
  }>;
  variantButton: number;
  background?: string;
  productID?: number;
  formikRef?: RefObject<FormikProps<ICalcSettingsState>>;
  nodeRef?: React.MutableRefObject<any>;
  onLeaveOrderClick?: (e: MouseEvent) => void;
}

const leasingTabData = [
  {
    title: 'Оптимальные условия',
    key: 'optimal',
  },
  {
    title: 'Минимальный платеж',
    key: 'minPay',
  },
  {
    title: 'Без переплат',
    key: 'noOverPay',
  },
  {
    title: 'Минимальный аванс',
    key: 'minAdvPay',
  },
];

const MainPageCalc: FC<MainPageCalcProps> = ({
  title,
  description,
  calcSettings,
  relevantCompetitors,
  FormCalc,
  variantButton,
  background,
  productID,
  formikRef,
  nodeRef,
  onLeaveOrderClick
}) => {
  const [calcResult, setCalcResult] = useContext(CalcResultContext);
  const [calcResultLoading, setCalcResultLoading] = useState<boolean>(false);
  const [calcResultError, setCalcResultError] = useState('');
  const [activeTab, setActiveTab] = useState('optimal');
  const [regionSelected, setRegionSelected] = useState(false);
  const cartItemsIDs = useSelector((state: RootState) => state.catalog.cartItemsIDs);

  const [calcCategories, setCalcCategories] = useState<
    Partial<Array<{title: string; value: string}>>
  >([]);

  useEffect(() => {
    const getCategories = async () => {
      const categories = await getCalcListCategory();
      const formatedCategories: Array<{title: string; value: string}> =
        categories.items.map((item: {title: string; guid: string}) => {
          return {title: item.title, value: item.guid};
        });
      setCalcCategories(formatedCategories);
    };
    getCategories();
  }, []);

  return (
    <MainPageCalcWrapper ref={nodeRef} background={background}>
      <GlobalContainer>
        <MainPageCalcTitleWrapper id="calculator">
          <Title>{title ? title : 'Калькулятор'}</Title>
          <MainPageCalcTitleText>
            {description
              ? description
              : 'Сделайте предварительный расчёт ежемесячного платежа'}
            {(variantButton === 3 || variantButton === 4) && (
              <TabsWrapper>
                <TabBtnGroup
                  tabs={leasingTabData}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  gap={20}
                />
              </TabsWrapper>
            )}
          </MainPageCalcTitleText>
        </MainPageCalcTitleWrapper>
        <MainPageCalcContainer>
          <RegionSelectedContext.Provider value={[regionSelected, setRegionSelected]}>
            <FormCalc
              setCalcResultLoading={setCalcResultLoading}
              calcSettings={calcSettings}
              relevantCompetitors={relevantCompetitors}
              categories={calcCategories}
              activeTab={activeTab}
              formikRef={formikRef}
              setCalcResult={setCalcResult}
              setCalcResultError={setCalcResultError}
              background={background}
            />
            <MainPageCalcResult
              calcResult={calcResult}
              loading={calcResultLoading}
              variantButton={variantButton}
              productID={productID}
              formikRef={formikRef}
              calcResultError={calcResultError}
              onLeaveOrderClick={onLeaveOrderClick}
            />
          </RegionSelectedContext.Provider>
        </MainPageCalcContainer>
      </GlobalContainer>
    </MainPageCalcWrapper>
  );
};

export default MainPageCalc;
