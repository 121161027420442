import {theme} from '@styles/theme';
import styled from 'styled-components';

interface StyledTabBtnGroupProps {
  marginTop?: number | undefined;
  spaceBetween?: boolean | undefined;
  gap?: number | undefined;
  center?: boolean;
  className?: string;
  border?: boolean;
  tabsFullWidth?: boolean;
  tabWidth?: string;
}

export const TabBtnGroupContainer = styled.div<StyledTabBtnGroupProps>`
  ${({border}) => (border ? `border-bottom: 1px solid ${theme.colors.lightGrayV2};` : '')}
`;

export const TabBtnGroupWrapper = styled.div<StyledTabBtnGroupProps>`
  display: flex;
  justify-content: ${({center}) => (center ? 'center' : 'justify-start')};
  align-items: flex-start;
  width: 100%;
  margin-top: ${({marginTop}) => (marginTop ? `${marginTop}px` : '')};
  ${({border}) => (border ? `border-bottom: 1px solid ${theme.colors.lightGrayV2};` : '')}
  overflow: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ::-moz-scrollbar {
    width: 0px;
    height: 0px;
    overflow: -moz-scrollbars-none;
  }
`;

export const Tabs = styled.div<StyledTabBtnGroupProps>`
  width: ${({tabsFullWidth}) => (tabsFullWidth ? '100%' : 'auto')};
  display: flex;
  justify-content: ${({spaceBetween}) => (spaceBetween ? 'space-between' : '')};
  gap: ${({gap}: StyledTabBtnGroupProps) => (gap ? `${gap}px` : '0px')};
  & p {
    ${({tabWidth}) => (tabWidth ? `width: ${tabWidth};` : 'width:  auto;')}
  }
`;
