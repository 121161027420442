import {CatalogProductCalcResult} from '@api/types/CatalogProduct';
import {CalcResult} from '@api/types/MainPage';
import Toast from '@components/atoms/Toast';
import {useWindowDimensions} from '@hooks';
import {RootState} from '@store';
import {addToCart, removeFromCart} from '@store/slices/catalog';
import {RegionSelectedContext} from '@utils/contexts';
import {calcResultMapper, divideNumber} from '@utils/formatters';
import {FormikProps} from 'formik';
import {isEqual} from 'lodash';
import Image from 'next/legacy/image';
import Link from 'next/link';
import {FC, RefObject, useContext, useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import ym from 'react-yandex-metrika';
import {ICalcSettingsState} from '../ProductCard/ProductCardCalc';
import {Tooltip} from '../Tooltip';
import {
  AdditionalInfoContainer,
  AdditionalInfoSmallText,
  AdditionalInfoText,
  AdditionalInfoTextFullWidth,
  AdditionalInfoTextResult,
  AdditionalInfoTextWrapper,
  AnotherText,
  AnotherTextResult,
  BtnWrapper,
  ButtonsWrapper,
  DesktopTooltipContainer,
  ErrorMessageWrapper,
  ImageWrapper,
  ImgWrapper,
  LeasingCalcButtonsWrapper,
  LinkButton,
  MainPageCalcAnotherTextContainer,
  MainPageCalcAttention,
  MainPageCalcResultAnotherTextWrapper,
  MainPageCalcResultText,
  MainPageCalcResultTitle,
  MainPageCalcResultWrapper,
  SkeletonWrapper,
  StyledButton,
  TextWithInformationIcon,
  TooltipContainer,
  TooltipContent,
  TooltipContentBold,
  TooltipContentText,
} from './MainPageCalcResult.styles';

interface ProductCardCalcResultProps {
  calcResult?: CatalogProductCalcResult | CalcResult | null;
  calcResultError?: string;
  loading?: boolean;
  testID?: string;
  variantButton?: number;
  productID?: number;
  formikRef?: RefObject<FormikProps<ICalcSettingsState>>;
  onLeaveOrderClick?: (e: MouseEvent) => void;
}

const SkeletonRow = ({testId}: {testId?: string}) => {
  return (
    <SkeletonWrapper data-testid={testId}>
      <Skeleton />
    </SkeletonWrapper>
  );
};

const DesktopTooltipComponent = ({title, text}: {title: string; text: string}) => (
  <DesktopTooltipContainer>
    <Tooltip
      overlay={
        <TooltipContent>
          <TooltipContentBold>{title}</TooltipContentBold>{' '}
          <TooltipContentText>{text}</TooltipContentText>
        </TooltipContent>
      }>
      <ImageWrapper>
        <Image
          src={'/assets/icons/informationIcon.svg'}
          layout="intrinsic"
          width={14}
          height={14}
          alt="information icon"
        />
      </ImageWrapper>
    </Tooltip>
  </DesktopTooltipContainer>
);

const TooltipComponent = ({title, text}: {title: string; text: string}) => (
  <TooltipContainer>
    <Tooltip
      overlay={
        <TooltipContent>
          <TooltipContentBold>{title}</TooltipContentBold>{' '}
          <TooltipContentText>{text}</TooltipContentText>
        </TooltipContent>
      }>
      <ImageWrapper>
        <Image
          src={'/assets/icons/informationIcon.svg'}
          layout="intrinsic"
          width={14}
          height={14}
          alt="information icon"
        />
      </ImageWrapper>
    </Tooltip>
  </TooltipContainer>
);

const MainPageCalcResult: FC<ProductCardCalcResultProps> = ({
  loading,
  testID,
  variantButton,
  productID,
  formikRef,
  calcResult,
  calcResultError,
  onLeaveOrderClick,
}) => {
  const {width} = useWindowDimensions();

  const cartItems = useSelector((state: RootState) => state.catalog.cartItems);
  const {mappedResultData, ...calcDataFromCart} =
    cartItems.find(item => item.id_product === productID) || {};
  const thisOrderCurrentlyInCart = isEqual(calcDataFromCart, formikRef?.current?.values);

  const [mappedCalcResult, setMappedCalcResult] = useState<Partial<CalcResult>>();

  const dispatch = useDispatch();

  const [regionSelected] = useContext(RegionSelectedContext);

  const deleteFromCart = () => {
    if (productID) {
      toast.warning(<Toast title="Товар удален из корзины" type="warn" />);
      dispatch(removeFromCart(+productID));
    }
  };

  const setToCart = () => {
    if (productID && !!mappedCalcResult && formikRef?.current) {
      dispatch(
        addToCart({
          ...formikRef.current.values,
          mappedResultData: mappedCalcResult,
        }),
      );
      toast.success(<Toast title="Товар добавлен в корзину" type="success" />);
      ym('reachGoal', 'v_korzinu');
    } else {
      toast.error(<Toast title="Ошибка. Повторите попытку" type="error" />);
    }
  };

  useEffect(() => {
    if (calcResult) {
      setMappedCalcResult(calcResultMapper(calcResult));
    }
  }, [calcResult]);

  return (
    <MainPageCalcResultWrapper data-testid={testID}>
      <MainPageCalcResultTitle>
        <h3> ЕЖЕМЕСЯЧНЫЙ ПЛАТЁЖ ОТ*</h3>
        <p>(с учётом НДС 20%)</p>
      </MainPageCalcResultTitle>
      {Boolean(calcResultError) && (
        <ErrorMessageWrapper>
          <AnotherText>Ошибка расчета</AnotherText>
        </ErrorMessageWrapper>
      )}
      {!calcResultError && (
        <>
          <MainPageCalcResultText>
            {mappedCalcResult?.each_payment && !loading ? (
              `${divideNumber(Math.floor(mappedCalcResult.each_payment!))} ₽`
            ) : (
              <SkeletonRow />
            )}
          </MainPageCalcResultText>
          <MainPageCalcAnotherTextContainer>
            <MainPageCalcResultAnotherTextWrapper>
              {mappedCalcResult?.riseInPricePrcY && !loading ? (
                <>
                  <TextWithInformationIcon>
                    <AnotherText>Среднегодовое удорожание</AnotherText>
                  </TextWithInformationIcon>
                  <AnotherTextResult>
                    {parseFloat(
                      mappedCalcResult.riseInPricePrcY.replace(',', '.'),
                    ).toFixed(1)}{' '}
                    %
                  </AnotherTextResult>
                </>
              ) : (
                <SkeletonRow />
              )}
            </MainPageCalcResultAnotherTextWrapper>
            <MainPageCalcResultAnotherTextWrapper>
              {mappedCalcResult?.order_summ && !loading ? (
                <>
                  <TextWithInformationIcon>
                    <AnotherText>Сумма договора лизинга</AnotherText>
                  </TextWithInformationIcon>
                  <AnotherTextResult>{mappedCalcResult.order_summ} ₽</AnotherTextResult>
                </>
              ) : (
                <SkeletonRow testId="skeleton" />
              )}
            </MainPageCalcResultAnotherTextWrapper>
          </MainPageCalcAnotherTextContainer>

          <AdditionalInfoContainer>
            <AdditionalInfoTextWrapper>
              {mappedCalcResult?.taxBenefit && !loading ? (
                <>
                  <TextWithInformationIcon>
                    <AdditionalInfoText>Возможная выгода по налогам</AdditionalInfoText>
                  </TextWithInformationIcon>
                  <AnotherTextResult>{mappedCalcResult.taxBenefit} ₽</AnotherTextResult>
                </>
              ) : (
                <SkeletonWrapper style={{marginTop: '5px'}}>
                  <Skeleton />
                </SkeletonWrapper>
              )}
            </AdditionalInfoTextWrapper>

            <AdditionalInfoTextWrapper>
              {mappedCalcResult?.taxRefund && !loading ? (
                <>
                  <TextWithInformationIcon>
                    <AdditionalInfoText>Возмещение НДС до</AdditionalInfoText>
                    <DesktopTooltipComponent
                      title="Возврат 20% НДС."
                      text="Компании на общей системе налогообложения могут оформить возврат 20% НДС от общей суммы договора лизинга"
                    />
                  </TextWithInformationIcon>
                  <AdditionalInfoTextResult>
                    {mappedCalcResult.taxRefund} ₽
                    <TooltipComponent
                      title="Возврат 20% НДС."
                      text="Компании на общей системе налогообложения могут оформить возврат 20% НДС от общей суммы договора лизинга"
                    />
                  </AdditionalInfoTextResult>
                </>
              ) : (
                <SkeletonRow />
              )}
            </AdditionalInfoTextWrapper>

            <AdditionalInfoTextWrapper>
              {mappedCalcResult?.taxEconomy && !loading ? (
                <>
                  <TextWithInformationIcon>
                    <AdditionalInfoText>
                      Экономия по налогу на прибыль до
                    </AdditionalInfoText>
                    <DesktopTooltipComponent
                      title="Снижение налога на прибыль."
                      text="Приобретаемое имущество находится на балансе АО «Росагролизинг», что позволяет сэкономить на налоге на прибыль компаниям, находящимся на общей системе налогообложения"
                    />
                  </TextWithInformationIcon>
                  <AdditionalInfoTextResult>
                    {mappedCalcResult.taxEconomy} ₽
                    <TooltipComponent
                      title="Снижение налога на прибыль."
                      text="Приобретаемое имущество находится на балансе АО «Росагролизинг», что позволяет сэкономить на налоге на прибыль компаниям, находящимся на общей системе налогообложения"
                    />
                  </AdditionalInfoTextResult>
                </>
              ) : (
                <SkeletonRow />
              )}
            </AdditionalInfoTextWrapper>
            {!!regionSelected &&
              (calcResult!.subsidy === null ? (
                <AdditionalInfoTextWrapper>
                  <TextWithInformationIcon>
                    <AdditionalInfoTextFullWidth bold>
                      В вашем регионе нет действующих мер поддержки
                    </AdditionalInfoTextFullWidth>
                  </TextWithInformationIcon>
                </AdditionalInfoTextWrapper>
              ) : (
                <div>
                  <AdditionalInfoTextWrapper noMargin>
                    <TextWithInformationIcon>
                      <AdditionalInfoText bold>
                        Возможная выгода по региональным субсидиям
                      </AdditionalInfoText>
                    </TextWithInformationIcon>
                    <AdditionalInfoTextResult>
                      {calcResult!.subsidy} ₽
                    </AdditionalInfoTextResult>
                  </AdditionalInfoTextWrapper>
                  <AdditionalInfoSmallText>
                    Расчет суммы с учетом НДС 20%
                  </AdditionalInfoSmallText>
                </div>
              ))}

            {variantButton === 1 && (
              <ButtonsWrapper>
                <Link href={'#applicationBlockForm'} passHref legacyBehavior>
                  <LinkButton>Получить консультацию</LinkButton>
                </Link>
                {Object.keys(calcDataFromCart).length !== 0 ? (
                  !!thisOrderCurrentlyInCart ? (
                    <StyledButton
                      title="Удалить из корзины"
                      variant="filled"
                      onClick={deleteFromCart}
                    />
                  ) : (
                    <StyledButton
                      title="Обновить в корзине"
                      variant="filled"
                      onClick={setToCart}
                    />
                  )
                ) : (
                  <StyledButton
                    title="Добавить в корзину"
                    variant="filled"
                    onClick={setToCart}
                  />
                )}
              </ButtonsWrapper>
            )}
            {variantButton === 2 ||
              (variantButton === 4 && (
                <BtnWrapper>
                  <StyledButton
                    title="Оставить заявку"
                    variant="filled"
                    link="#applicationBlockForm"
                    onClick={onLeaveOrderClick}
                  />
                </BtnWrapper>
              ))}

            {variantButton === 3 && (
              <LeasingCalcButtonsWrapper>
                <StyledButton
                  title="Оставить заявку"
                  variant="filled"
                  link="#applicationBlockForm"
                  onClick={onLeaveOrderClick}
                />

                <Link href={'/catalog'} passHref>
                  <LinkButton style={{marginBottom: width! > 530 ? '0' : '20px'}}>
                    Перейти в каталог
                    <ImgWrapper>
                      <Image
                        src={'/assets/icons/rightArrowLongGreen.svg'}
                        width={12}
                        height={12}
                        alt="right arrow"
                      />
                    </ImgWrapper>
                  </LinkButton>
                </Link>
              </LeasingCalcButtonsWrapper>
            )}

            <MainPageCalcAttention>
              * — Предложение носит информационный характер и не является публичной
              офертой по ст.437 (2) ГК РФ. Все приведенные расчеты являются
              предварительными. Для получения точных условий обратитесь, пожалуйста, к
              менеджерам.
            </MainPageCalcAttention>
          </AdditionalInfoContainer>
        </>
      )}
    </MainPageCalcResultWrapper>
  );
};

export default MainPageCalcResult;
