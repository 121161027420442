import TabButton from '@components/atoms/TabButton';
import {GlobalContainer} from '@styles/pages/_app.styles';
import {FC, useEffect, useRef} from 'react';
import {TabBtnGroupContainer, TabBtnGroupWrapper, Tabs} from './TabBtnGroup.styles';

interface TabBtnGroupProps {
  activeTab: string;
  setActiveTab: (key: string) => void;
  tabs: Partial<Array<{title: string; key: string; hasMarker?: boolean}>>;
  marginTop?: number;
  global?: boolean;
  spaceBetween?: boolean;
  gap?: number;
  center?: boolean;
  className?: string;
  border?: boolean;
  tabBtnOnClick?: (key: string) => void;
  tabsFullWidth?: boolean;
  tabFullWidth?: boolean;
  tabWidth?: string;
  disableTabsAfterActive?: boolean;
  disabledTabs?: string[];
}

interface StyledTabBtnGroupProps {
  marginTop?: number | undefined;
  spaceBetween?: boolean | undefined;
  gap?: number | undefined;
  center?: boolean;
  className?: string;
  border?: boolean;
  tabsFullWidth?: boolean;
  tabWidth?: string;
}

const TabBtnGroup: FC<TabBtnGroupProps> = ({
  tabs,
  activeTab,
  setActiveTab,
  marginTop,
  global = false,
  spaceBetween = false,
  gap = 36,
  center = false,
  className,
  border = true,
  tabBtnOnClick,
  tabsFullWidth = false,
  tabFullWidth = false,
  tabWidth,
  disableTabsAfterActive,
  disabledTabs = [],
}) => {
  const scrollContainer: any = useRef(null);
  const activeItem: any = useRef(null);

  useEffect(() => {
    const scrollRect = scrollContainer.current?.getBoundingClientRect();
    const activeRect = activeItem.current?.getBoundingClientRect();
    if (!scrollRect || !activeRect) {
      return;
    }
    const scrollLeftPosition =
      activeRect.left - scrollRect.left - scrollRect.width / 2 + activeRect.width / 2;
    scrollContainer.current.scrollLeft += scrollLeftPosition - 25;
  }, [activeItem.current]);

  return (
    <>
      {global ? (
        <TabBtnGroupContainer border={border}>
          <GlobalContainer>
            <TabBtnGroupWrapper
              center={center}
              marginTop={marginTop}
              border={false}
              ref={scrollContainer}
              tabsFullWidth={tabsFullWidth}>
              <Tabs
                spaceBetween={spaceBetween}
                gap={gap}
                tabsFullWidth={tabsFullWidth}
                tabWidth={tabWidth}>
                {tabs.map(tab => (
                  <TabButton
                    className={className}
                    title={tab?.title}
                    active={activeTab === tab?.key}
                    onClick={() => {
                      setActiveTab(tab?.key ?? '');
                      if (tabBtnOnClick) {
                        tabBtnOnClick(tab?.key ?? '');
                      }
                    }}
                    key={tab?.key}
                    tabFullWidth={tabFullWidth}
                    testID={`testTab-${tab?.key}`}
                    activeRef={activeItem}
                    hasMarker={tab?.hasMarker}
                  />
                ))}
              </Tabs>
            </TabBtnGroupWrapper>
          </GlobalContainer>
        </TabBtnGroupContainer>
      ) : (
        <TabBtnGroupWrapper
          center={center}
          marginTop={marginTop}
          border={border}
          ref={scrollContainer}
          tabsFullWidth={tabsFullWidth}>
          <Tabs
            spaceBetween={spaceBetween}
            gap={gap}
            tabsFullWidth={tabsFullWidth}
            tabWidth={tabWidth}>
            {tabs.map(tab => (
              <TabButton
                className={className}
                title={tab?.title}
                active={activeTab === tab?.key}
                onClick={() => {
                  setActiveTab(tab?.key ?? '');
                  if (tabBtnOnClick) {
                    tabBtnOnClick(tab?.key ?? '');
                  }
                }}
                key={tab?.key}
                tabFullWidth={tabFullWidth}
                testID={`testTab-${tab?.key}`}
                activeRef={activeItem}
                disabled={
                  (disableTabsAfterActive && +activeTab < Number(tab?.key)) ||
                  !!disabledTabs?.find(key => key === tab?.key)
                }
                hasMarker={tab?.hasMarker}
              />
            ))}
          </Tabs>
        </TabBtnGroupWrapper>
      )}
    </>
  );
};

export default TabBtnGroup;
