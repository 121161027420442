import {
  MainCalcTooltipListWrapper,
  TooltipList,
  TooltipListItem,
} from './MainCalcTooltipList.styles';

const MainCalcTooltipList = ({items}: {items: {title: string; code: string}[]}) => {
  return (
    <MainCalcTooltipListWrapper>
      <TooltipList>
        {items.map((item, i) => (
          <TooltipListItem key={i}>
            <li>{item.title}</li>
          </TooltipListItem>
        ))}
      </TooltipList>
    </MainCalcTooltipListWrapper>
  );
};

export default MainCalcTooltipList;
