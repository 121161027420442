import {theme} from '@styles/theme';
import styled from 'styled-components';
import {StyledTabBtnProps} from './TabButton';

export const ProdCardTab = styled.p<StyledTabBtnProps>`
  position: relative;
  width: ${({tabFullWidth}) => (tabFullWidth ? '100%' : 'auto')};
  text-align: center;
  color: ${({active}) =>
    active ? `${theme.colors.brandGreenV2}` : `${theme.colors.darkText}`};
  border-bottom: ${({active}) =>
    '3px solid ' + (active ? theme.colors.brandGreenV2 : 'transparent')};
  transition: color 0.5s, border-color 0.25s;
  cursor: pointer;
  position: relative;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.1px;
  padding-bottom: 13px;
  white-space: nowrap;
  pointer-events: ${({disabled}) => (disabled ? 'none' : 'all')};
  &:hover {
    color: ${theme.colors.brandGreenV2};
    border-bottom-color: ${theme.colors.brandGreenV2};
  }
`;

export const Marker = styled.span`
  position: absolute;
  top: 2px;
  right: -12px;
  display: block;
  width: 6px;
  height: 6px;
  background: ${theme.colors.opalRed};
  border-radius: 15px;
`;
