import Input from '@components/atoms/Input';
import {FC, useEffect, useState} from 'react';
import {NumericFormat} from 'react-number-format';
import RangeInputC from './RangeProgressInput';
import {Wrapper} from './RangeWrapper.styles';

interface RangeInputProps {
  id: string;
  label?: string;
  value: string | number;
  onChange?: (e: number) => void;
  min: number;
  max: number;
  labelBG?: string;
  className?: string;
  suffix?: string;
  textColor?: string;
  error?: string;
  sliderBGColor?: string;
  disabled?: boolean;
}

const RangeInputWrapper: FC<RangeInputProps> = ({
  id,
  label,
  value,
  onChange,
  min,
  max,
  labelBG,
  className,
  suffix,
  textColor,
  error,
  sliderBGColor,
  disabled
}) => {
  const [internalValue, setInternalValue] = useState(value);

  const changeValues = (value: number) => {
    setInternalValue(value);
    onChange!(value);
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <Wrapper className={className}>
      <NumericFormat
        id={id}
        onValueChange={(value: any) => {
          setInternalValue!(value.floatValue!);
        }}
        label={label}
        value={internalValue}
        labelBGColor={labelBG}
        thousandSeparator=" "
        customInput={Input}
        suffix={suffix ? suffix : ''}
        textColor={textColor}
        max={max}
        min={min}
        onBlur={e => {
          const currentValue = parseFloat(e.target.value.split(' ').join(''));

          if (currentValue > max) {
            changeValues(max);
          } else if (currentValue < min) {
            changeValues(min);
          } else {
            changeValues(currentValue);
          }
        }}
        allowNegative={false}
        error={error}
        disabled={disabled}
      />
      <RangeInputC
        id={id}
        onChange={onChange}
        value={value}
        min={min}
        max={max}
        bgColor={sliderBGColor}
        disabled={disabled}
      />
    </Wrapper>
  );
};

export default RangeInputWrapper;
