import {theme} from '@styles/theme';
import styled from 'styled-components';

export const MainCalcTooltipListWrapper = styled.div`
  max-width: 280px;
  min-width: 280px;
  border: 2px solid #dbdbdb;
  border-radius: 4px;
  width: 280px;
  z-index: 999;
  transition: opacity 0.5s;
  background-color: white;

  @media (max-width: 840px) {
    max-width: 230px;
    min-width: 230px;
  }
  @media (max-width: 640px) {
    max-width: 180px;
    min-width: 180px;
  }
  @media (max-width: 360px) {
    max-width: 150px;
    min-width: 150px;
  }
`;

export const TooltipList = styled.ul`
  list-style: none;
  position: relative;
  padding: 15px;
  & li {
    margin-right: 10px;
  }
  @media (max-width: 640px) {
    padding: 5px;
  }
`;

export const TooltipListItem = styled.ul`
  display: flex;

  &:before {
    content: '\\2014';
    margin-right: 10px;
    color: ${theme.colors.gray};
    @media (max-width: 840px) {
      line-height: 14px;
    }
  }
  li {
    font-size: 13px;
    line-height: 20px;
    color: ${theme.colors.gray};

    @media (max-width: 840px) {
      font-size: 10px;
      line-height: 14px;
    }
  }
`;
