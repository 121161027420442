import styled from 'styled-components';

import BlockTitle from '@components/atoms/BlockTitle';
import {theme} from '@styles/theme';

export const MainPageCalcWrapper = styled.div<{background?: string}>`
  background: ${p => (p.background ? p.background : `${theme.colors.lightBG}`)};
  padding-top: 70px;
  padding-bottom: 110px;

  @media (max-width: 768px) {
    padding: 50px 0;
  }
`;

export const MainPageCalcTitleWrapper = styled.div`
  margin-bottom: 20px;
`;
export const MainPageCalcTitleText = styled.div`
  font-weight: 400;
  font-size: 18px;
  color: ${({theme}) => theme.colors.gray};
  line-height: 21px;
  margin-top: 20px;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    margin-top: 10px;
    font-size: 16px;
  }
  @media (max-width: 560px) {
    font-size: 15px;
  }
`;

export const MainPageCalcContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 50px;
  @media (max-width: 1125px) {
    flex-direction: column;
  }
`;

export const Title = styled(BlockTitle)`
  color: ${theme.colors.darkText};
  line-height: 44px;

  @media (max-width: 768px) {
    padding: 0;
    font-size: 24px;
    line-height: 28px;
  }
  @media (max-width: 560px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const TabsWrapper = styled.div`
  width: 100%;
  height: 32px;
  margin-bottom: 50px;
  margin-top: 36px;
`;
