import {FC} from 'react';
import {BlockTitleWrapper} from './BlockTitle.styles';

interface BlockTitleProps {
  className?: string;
  children: string;
}

const BlockTitle: FC<BlockTitleProps> = ({children, className}) => {
  return <BlockTitleWrapper className={className}>{children}</BlockTitleWrapper>;
};

export default BlockTitle;
