import Button from '@components/atoms/Button';
import {theme} from '@styles/theme';
import styled from 'styled-components';

export const MainPageCalcResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 40%;
  transition: all 0.25s;
`;

export const MainPageCalcResultTitle = styled.div`
  display: flex;
  justify-content: center;
  letter-spacing: 0.5px;
  width: 100%;
  margin-bottom: 20px;
  gap: 10px;
  @media (max-width: 768px) {
    gap: 20px;
  }
  @media (max-width: 560px) {
    flex-direction: column;
    gap: 10px;
  }
  h3 {
    font-family: ${theme.fonts.headline};
    color: ${theme.colors.darkText};
    text-transform: uppercase;
    line-height: 18px;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    margin: 0;
  }
  p {
    color: ${theme.colors.gray};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  @media (max-width: 446px) {
    text-align: center;
  }
`;

export const MainPageCalcResultText = styled.div`
  display: flex;
  justify-content: center;
  font-family: ${theme.fonts.headline};
  font-weight: 600;
  font-size: 52px;
  letter-spacing: -0.25px;
  line-height: 60px;
  color: ${({theme}) => theme.colors.brandGreenV2};
  margin-bottom: 30px;
  @media (max-width: 446px) {
    text-align: center;
  }
`;

export const MainPageCalcAnotherTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  border-bottom: 1px solid ${theme.colors.lightGrayV2};
  margin-bottom: 12px;
`;

export const MainPageCalcResultAnotherTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 24px;
  :last-child {
    margin-bottom: 0;
  }
`;

export const AnotherText = styled.div`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${theme.colors.gray};
  @media (max-width: 500px) {
    max-width: 100%;
  }
`;

export const AnotherTextResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.1px;
  line-height: 21px;
  color: ${theme.colors.darkText};
  min-width: 110px;
  flex-shrink: 0;

  @media (max-width: 500px) {
    align-self: center;
  }
`;

export const AdditionalInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AdditionalInfoTextWrapper = styled.div<{noMargin?: boolean}>`
  display: flex;
  justify-content: space-between;
  line-height: 24px;
  :last-child {
    margin-bottom: 0;
  }
`;

export const AdditionalInfoText = styled.div<{bold?: boolean}>`
  flex: 1 1 55.13%;
  font-size: 14px;
  font-weight: ${({bold}) => (bold ? '600' : '400')};
  letter-spacing: 0.1px;
  margin-right: 11px;
  color: ${theme.colors.gray};

  @media (max-width: 500px) {
    max-width: 100%;
  }
`;

export const AdditionalInfoTextFullWidth = styled.div<{bold?: boolean}>`
  flex: 1 1 55.13%;
  font-size: 14px;
  font-weight: ${({bold}) => (bold ? '600' : '400')};
  letter-spacing: 0.1px;
  color: ${theme.colors.opalRed};
  margin-top: 15px;
`;

export const AdditionalInfoTextResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  min-width: 110px;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.1px;
  line-height: 24px;
  color: ${theme.colors.darkText};
  flex-shrink: 0;
  @media (max-width: 500px) {
    align-self: center;
  }
`;

export const AdditionalInfoSmallText = styled.div`
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 16px;
  color: ${({theme}) => theme.colors.gray};
`;

export const MainPageCalcAttention = styled.div`
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 16px;
  color: ${({theme}) => theme.colors.gray};
  margin-top: 20px;
  @media (max-width: 1125px) {
    align-self: center;
    text-align: center;
    max-width: 75%;
  }
  @media (max-width: 500px) {
    max-width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-self: center;
  height: 50px;
  font-size: 16px;
  line-height: 20px;
  padding: 15px 61px;
  width: 250px;
  min-height: 50px;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const TextWithInformationIcon = styled.div`
  display: flex;
  align-items: center;
  & img {
    filter: grayscale(100%);
  }
  & img:hover {
    filter: grayscale(0%);
  }

  span {
    @media (max-width: 560px) {
      margin-top: 5px !important;
    }
  }
  @media (max-width: 500px) {
    align-items: start;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  @media (max-width: 800px) {
    justify-content: space-between;
  }
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const LinkButton = styled.div`
  display: flex;
  font-size: 14px;
  color: ${theme.colors.brandGreenV2};
  line-height: 24px;
  cursor: pointer;
  @media (max-width: 530px) {
    margin-top: 0px;
  }
`;

export const ImgWrapper = styled.div`
  margin-left: 8px;
`;

export const LeasingCalcButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
  @media (max-width: 800px) {
    justify-content: center;
    gap: 107px;
  }
  @media (max-width: 530px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const ImageWrapper = styled.div`
  margin-left: 11px;
  margin-top: 3px;
`;

export const DesktopTooltipContainer = styled.div`
  display: block;
  cursor: pointer;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const TooltipContainer = styled.div`
  display: none;
  cursor: pointer;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const ErrorBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
`;

export const TooltipContent = styled.div`
  background-color: #ffffff;
  padding: 15px;
  border: 2px solid ${theme.colors.lightGrayV2};
  border-radius: 4px;
  max-width: 280px;
  min-width: 280px;
  @media (max-width: 520px) {
    font-size: 14px;
    line-height: 20px;
    max-width: 260px;
    min-width: 260px;
  }
`;

export const TooltipContentBold = styled.span`
  font-weight: 600;
`;

export const TooltipContentText = styled.div`
  font-weight: 400;
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 20px 0;
  a {
    width: fit-content;
    height: fit-content;
  }
  @media (max-width: 500px) {
    button,
    a {
      width: 100%;
    }
  }
`;

export const SkeletonWrapper = styled.div`
  width: 100%;
`;

export const ErrorMessageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
