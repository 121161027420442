import {useFormikContext} from 'formik';
import {debounce} from 'lodash';
import React, {FC, useCallback, useEffect} from 'react';

interface ProductCardCalcAutoSaveProps {
  testID?: string;
}

const ProductCardCalcAutoSave: FC<ProductCardCalcAutoSaveProps> = ({testID}) => {
  const formik = useFormikContext();
  const autoSubmit = useCallback(
    debounce(async () => await formik.submitForm(), 500),
    [],
  );

  useEffect(() => {
    autoSubmit();
  }, [formik.submitForm, formik.values]);

  return <div data-testid={testID}></div>;
};

export default ProductCardCalcAutoSave;
