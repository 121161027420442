import {theme} from '@styles/theme';
import styled from 'styled-components';

export const BlockTitleWrapper = styled.h2`
  font-family: ${theme.fonts.headline};
  font-weight: 400;
  font-size: 36px;
  letter-spacing: -0.25px;
  @media (max-width: 800px) {
    font-size: 24px;
    line-height: 28px;
  }
  @media (max-width: 550px) {
    font-size: 20px;
    line-height: 24px;
  }
`;
