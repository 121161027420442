import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

interface RangeProgressInputProps {
  id: string;
  max: number;
  min: number;
  onChange?: any;
  value: string | number;
  bgColor?: string;
  disabled?: boolean;
}

const RangeProgressInput = ({
  id,
  max,
  min,
  onChange,
  value,
  bgColor,
  disabled
}: RangeProgressInputProps) => {
  const inputRef: any = useRef(null);
  const [isChanging, setIsChanging] = useState(false);

  const getPercent = useMemo(
    () => (value: number) => ((value - min!) / (max! - min!)) * 100,
    [max, min],
  );

  const changeInputProgressPercentStyle = useCallback(() => {
    inputRef.current.style.setProperty(
      '--webkitProgressPercent',
      `${getPercent(inputRef.current.value)}%`,
    );
  }, [getPercent, value]);

  useEffect(() => {
    changeInputProgressPercentStyle();
    const inputElement = inputRef.current && inputRef.current;

    const handleUpAndLeave = () => setIsChanging(false);
    const handleDown = () => setIsChanging(true);

    inputElement.addEventListener('touchstart', handleDown);
    inputElement.addEventListener('touchmove', changeInputProgressPercentStyle);
    inputElement.addEventListener('touchend', handleUpAndLeave);

    inputElement.addEventListener('mousemove', changeInputProgressPercentStyle);
    inputElement.addEventListener('mousedown', handleDown);
    inputElement.addEventListener('mouseup', handleUpAndLeave);
    inputElement.addEventListener('mouseleave', handleUpAndLeave);
    return () => {
      inputElement.removeEventListener('touchstart', handleDown);
      inputElement.removeEventListener('touchmove', changeInputProgressPercentStyle);
      inputElement.removeEventListener('touchend', handleUpAndLeave);

      inputElement.removeEventListener('mousemove', changeInputProgressPercentStyle);
      inputElement.removeEventListener('mousedown', handleDown);
      inputElement.removeEventListener('mouseup', handleUpAndLeave);
      inputElement.removeEventListener('mouseleave', handleUpAndLeave);
    };
  }, [isChanging, changeInputProgressPercentStyle]);

  useEffect(() => {
    if (!inputRef?.current) return;
    changeInputProgressPercentStyle();
  }, [inputRef, changeInputProgressPercentStyle]);

  return (
    <input
      id={id}
      type="range"
      ref={inputRef}
      onChange={e => onChange(e.target.value)}
      value={value}
      min={min}
      max={max}
      style={{
        position: 'absolute',
        width: '95%',
        zIndex: 10,
        backgroundColor: bgColor ?? 'white',
      }}
      disabled={disabled}
    />
  );
};

export default RangeProgressInput;
